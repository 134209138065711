export const projectData = [
    {
      "id": 1,
      "title": "Glamp Camp",
      "img": "../glampmobile.jpg",
      "body": "Developed innovative and interactive website for family run business. Created code using HTML/CSS practices while working closely with business owners to ensure all requirements and design specifications are met. Conducted researched on different software programs and maintained software documentation accordingly.",
      "tech": ["Gatsby", "SASS", "Javascript", "GraphQL", "Wordpress CMS"]
    },
    {
      "id": 2,
      "title": "Square Off",
      "img": "../squareoffmobile.jpg",
      "body": "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
      "tech": ["HTML", "CSS", "Javascript", "jQuery"]
    },
    {
        "id": 3,
        "title": "Beats13",
        "img": "../mobile.jpg",
        "body": "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto",
        "tech": ["Custom built Wordpress", "SASS", "Javascript", "GraphQL", "WooCommerce"]
      },
    {
        "id": 4,
        "title": "Quote Generator",
        "img": "../quotemobile.jpg",
        "body": "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
        "tech": ["HTML", "CSS", "Javascript"]
    },
    {
        "id": 5,
        "title": "Brooklyn Film Festival",
        "img": "../boffmobile.jpg",
        "body": "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto",
        "tech": ["HTML", "CSS"]
    },
    {
        "id": 6,
        "title": "Gadget Globe",
        "img": "../gadgetmobile.jpg",
        "body": "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
        "tech": ["Shopify Theme", "Dropshipping Products"]
    }
];