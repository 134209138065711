import React, { useState } from 'react';
import Layout from '../components/Layout';
import squareOff from '../images/squareOff.png';
import hhGen from '../images/quote.png';
import bff from '../images/bff.png';
import glamp from '../images/glamp.jpg';
import beats13 from '../images/beats13.jpg';
import gadgetglobe from '../images/gadgetglobe.jpg';
import Modal from '../components/Modal';
import { projectData } from '../../projects';

const Projects = () => {
    const [singleData, setSingleData] = useState({
        "id": null,
        "title": null,
        "img": null,
        "body": null,
        "tech": []
      },);
      
    const [showModal, setShowModal] = useState(false);

    const setProjectData = (project) => {
        console.log('setting state')
        console.log(project)
        setSingleData(project)
    };

    const openModal = () => {
      setShowModal(prev => !prev);
    };

    return (
        <>
            <Modal showModal={showModal}
                   setShowModal={setShowModal}
                   singleData={singleData} />
            <Layout>
                <React.Fragment>

                <h1>Projects</h1>
                    <h5>Click images for project information</h5>
                <section className="projects py-5 pb-5 container containerStyle">
                    <div className="item">
                        <img onMouseOver={() => setProjectData(projectData[0])} onClick={openModal} className="proj" src={glamp} alt="Glamp Camp Website" />
                        <a href="https://glamp-camp.co.uk" className="btn-light" target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-eye"></i> View Project
                        </a>
                        <a href="https://github.com/PAdkins84/glampcamp" className="btn-dark" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-github"></i> View Code
                        </a>
                    </div>
                    <div className="item">
                        <img onMouseOver={() => setProjectData(projectData[1])} onClick={openModal} className="proj" src={squareOff} alt="Square Off Game" />
                        <a href="http://padkins.dev/projects/squareoff/BoardGame.html" className="btn-light" target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-eye"></i> View Project
                        </a>
                        <a href="https://github.com/PAdkins84/P6Game-Unfinished-" className="btn-dark" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-github"></i> View Code
                        </a>
                    </div>
                    <div className="item">
                        <img onMouseOver={() => setProjectData(projectData[2])} onClick={openModal} className="proj" src={beats13} alt="Beats13 Website" />
                        <a href="https://beats13.store" className="btn-light" target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-eye"></i> View Project
                        </a>
                        <a href="https://github.com/PAdkins84/beats13" className="btn-dark" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-github"></i> View Code
                        </a>
                    </div>
                    <div className="item">
                        <img onMouseOver={() => setProjectData(projectData[3])} onClick={openModal} className="proj" src={hhGen} alt="Hip Hop Quote Generator" />
                        <a href="http://padkins.dev/projects/jsgen/quotegen.html" className="btn-light" target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-eye"></i> View Project
                        </a>
                        <a href="https://github.com/PAdkins84/P5QuoteGen" className="btn-dark" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-github"></i> View Code
                        </a>
                    </div>
                    <div className="item">
                        <img onMouseOver={() => setProjectData(projectData[4])} onClick={openModal} className="proj" src={bff} alt="Brooklyn Film Festival" />
                        <a href="http://padkins.dev/projects/boff/filmfestival.html" className="btn-light" target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-eye"></i> View Project
                        </a>
                        <a href="https://github.com/PAdkins84/P3Boff" className="btn-dark" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-github"></i> View Code
                        </a>
                    </div>
                    <div className="item mb-5">
                        <img onMouseOver={() => setProjectData(projectData[5])} onClick={openModal} className="proj" src={gadgetglobe} alt="Chalets and caviar project" />
                        <a href="https://gadgetglobe.net" className="btn-light" target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-eye"></i> View Project
                        </a>
                        <a href="https://github.com/PAdkins84/P2CandC" className="btn-dark isDisabled" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-github"></i> View Code
                        </a>
                    </div>
                </section>
            </React.Fragment>
            </Layout>
        </>
    )
}

export default Projects