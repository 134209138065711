import React from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

const Background = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;

const ModalWrapper = styled.div`
    width: 800px;
    height: 500px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 9999;
    border-radius: 10px;
    overflow-y: scroll;
`;

const ModalImg = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    background: #000;
    overflow-y: scroll;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1.8;
    color: #141414;
    margin: 40px 10px 0 10px;

    p {
        margin-bottom: 1rem;
    }

    button {
        padding: 10px 24px;
        background: #141414;
        color: #fff;
        border: none;
        width: 15rem;
    }
`;

const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 9999;
    color: #d0a6ff;
    background-color: rgba(0, 0, 0, 0.82);
    border-radius: 5px;
`

const Modal = ({showModal, setShowModal, singleData}) => {
    return (
        <>
            {showModal ? (
                <Background>
                    <ModalWrapper className="modalWrapper">
                        <ModalImg className="modalImage" src={singleData.img} alt='test modal' />
                        <ModalContent className="modalContent">
                        {/* {projectData.map((data, id) => {
                            return (
                            <h3 key={id}>{data.title}</h3>  
                            )  
                        })} */}
                        <h3>{singleData.title}</h3>
                        <p>{singleData.body}</p>

                        <h4>Technologies used:</h4>
                        <ul>
                            {singleData.tech.map((data, id) => {
                                return (
                                    <li key={id}>{data}</li>  
                                )  
                            })}
                        </ul>

                            {/* <button className="mt-5">View Project</button> */}
                        </ModalContent>
                        <CloseModalButton aria-label='close modal' onClick={() => setShowModal(prev => !prev)} />
                    </ModalWrapper>
                </Background>
            ) : null}
        </>
    )
}

export default Modal
